import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import SingleProductLander from "../../../components/Products/SingleProductLander"
import { SectionSingleProduct } from "../../../styledComponents/section"
import { StyledSignUpFreeSleepFormLanderMindfulness } from "../../../styledComponents/signUpFormLanderMindfulnessCollection"
import SignupFreeSleepForm from "../../../components/formFreeSleepSignUp"
import HeroBannerSleep from "../../../components/HeroBannerSleep"
import { HeroHeaderSleep, HeroTextSleep, HeroDescriptionSleep } from "../../../styledComponents/heroBannerSleep"
import { StyledLanderMindfulnessCollection } from "../../../styledComponents/lander/mindfulnessCollection"
import { StyledButton } from "../../../styledComponents/button"
import { PromoPatSection } from "../../../styledComponents/promoPatSection"
import PermissioningProductPromo from "../../../components//Products/PermissioningProductPromo"

import SleepCopy from "../../../fixtures/sleepWebPageShopCopy"
import PermissioningProductPromoLanderUSVersion from "../../../components/Products/PermissioningProductPromoLanderUSVersion"
import SingleProductLanderOfffer1 from "../../../components/Products/SingleProductLanderOfffer1"


const SleepOffer1 = ({ data: { product, fullPrice, salePrice, patPrograms }, location }) => {
  const [hasChat, setChat] = useState(undefined)

  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <Layout location={location} isLander isOffer>
      <SectionSingleProduct>
              <SingleProductLanderOfffer1 
                title={product.title}
                uri={product.uri}
                excerpt={product.excerpt}
                featuredImage={product.featuredImage}
                content={product.content}
                sale={product.productData.pricesale}
                fullPrice={fullPrice}
                salePrice={salePrice}
                sku={fullPrice}
                id={product.id}
                tag={product.productsTag}
                showShareTools={false}
              />
        <StyledLanderMindfulnessCollection>
          {/* <SleepCopy title="Permission to Reset Sleep" fullPriceWithDecimal="77.99" salePriceWithDecimal="37.77" sku="price_1JycJ0IfnzsCIRSOCoV8WNMU"/> */}
        </StyledLanderMindfulnessCollection>
      </SectionSingleProduct>
    </Layout>
    </>
  )
}

export default SleepOffer1

export const pageQuery = graphql`
query SleepProgramPost {
    # selecting the current post by id
    product: wpProduct(id: {eq: "cG9zdDo3NzY5"}) {
      id
      title
      content
      uri
      excerpt
      productData {
        linkToAmazon
        pricefull
        pricesale
        stripefullprice
        stripesaleprice
      }
      productsTag {
            nodes {
              name
              slug
            }
          }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 975
                quality: 100
                placeholder: TRACED_SVG
                breakpoints: [320, 720, 1024, 1600]
              )
            }
            publicURL
          }
        }
      }
    }
    fullPrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1KWRU7IfnzsCIRSOydEE5ERY" }}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JycJ0IfnzsCIRSOCoV8WNMU" }}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    patPrograms:   allWpProduct(filter: {productsTag: {nodes: {elemMatch: {name: {eq: "sleep-anxiety"}}}}}) {
      edges {
        node {
          title
          uri
          id
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
