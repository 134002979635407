import React, { useState } from "react"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  ProductFigureCaption,
  StyledProductSingleCard,
  StyledProductSingleFigure,
  StyledProductSingleHeader,
  StyledProductBookingButtons,
  StyledProductExcerptSingle,
  StyledNotice,
  StyledShareSocialTools
} from "./StyledPrimaryProductCard"
  import {
    StyledRegisterNowButton
  } from "../../styledComponents/button"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import Icon from "../icon"

import lunchtimeReset from "../../fixtures/mindfulness-collection-lunchtime-reset"
import { StyledModal,StyledModalHeader } from "../../styledComponents/modal"
import { StyledButton, StyledCloseModalButton, StyledAnchorLink } from "../../styledComponents/button"
import KarinaGrantLogo from "../logo"
import AudioCard from "../App/audioCard" 
import { StyledAudio } from "../../styledComponents/app/audioCard"
import AudioModal from "../App/AudioModal"
import PermissioningSalesForm from "./PermissioningSalesForm"

import SleepCopy from "../../fixtures/sleepWebPageShopCopy"
import ResetAnxiety from "../../fixtures/resetAnxietyWebPageShopCopy"
import ResetCopy from "../../fixtures/resetWebPageShopCopy"
import ShareTools from "../socialLinks"

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  
  function closeModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  let collection 
  
  if(data.id == 'cG9zdDo3Mzcz') collection = lunchtimeReset
  
  const image = {
    imageData: data.image,
    alt: ``,
    description: "",
  }

  
  return (
      <>
      <StyledButton className="listen-now" onClick={toggleModal}>Listen now</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        // className="audio-list-modal"
        className={collection[0].type == 'shm-program' ? 'shm-program-modal' : 
        collection[0].type == 'permission-reset-sleep'  ? 'permission-reset-sleep-modal' : 'audio-list-modal' }
      >
        <StyledModalHeader type={collection[0].type}>
          <div>
            <KarinaGrantLogo />
            <StyledCloseModalButton className="close-audio" onClick={closeModal}>
              <Icon name="cross" />
            </StyledCloseModalButton>
          </div>
        </StyledModalHeader>  
          <StyledAudio className={collection[0].type == 'single' && 'single-audio'}>
            {
            collection[0].type == 'single' ? <AudioModal audio={collection[0]}/> :
            collection[0].audioFiles.map(audio => {
                return (
                <AudioCard 
                  key={audio.title + `-1`}
                  title={audio.title}
                  image={audio.image}
                  color={audio.color}
                  audioMp3={audio.audioMp3}
                  duration={audio.duration}
                />     
              )
            })
          }
          </StyledAudio>
        </StyledModal>
      </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;


const SingleProductLanderOfffer1 = props => {
  const { 
    title, 
    featuredImage, 
    uri, 
    excerpt, 
    fullPrice, 
    salePrice,
    content,
    id
  } = props
  const { addItem, checkoutSingleItem } = useShoppingCart()
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  
  let FullPrice 
  if(id === 'cG9zdDo3MjM0') {
    FullPrice = '22'
  }
  else {
    FullPrice = fullPrice.edges[0].node.unit_amount  
  }

  const fullPriceFormatted = formatCurrencyString({value: parseInt('9999'),currency: 'USD'})
  const salePriceFormatted = formatCurrencyString({value: parseInt('4799'),currency: 'USD'})
  const re = /\b(\d+)(\d{2})\b/
  const fp = FullPrice
  const sp = salePrice.edges[0].node.unit_amount
  const subst = '$1.$2'
  const fullPriceWithDecimal = fp.toString().replace(re, subst)
  const salePriceWithDecimal = sp.toString().replace(re, subst)

  let collection 
  if(id == 'cG9zdDo3Mzcz') collection = lunchtimeReset
  const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}`+ uri 
  return (
    <StyledProductSingleCard
      itemScope
      itemType="http://schema.org/Product"
    >
      <meta itemProp="availability" content="https://schema.org/InStock" />
      <meta itemProp="priceCurrency" content="GBP" />
      <meta itemProp="price" content={fullPrice} />
      <meta itemProp="url" content={uri} />
      <span>
        {image && image.imageData && (
          <StyledProductSingleFigure>
            <GatsbyImage
              image={image.imageData}
              alt={image.alt}
              width="100%"
              padding-bottom="0" />
            <ProductFigureCaption>{image.description}</ProductFigureCaption>
          </StyledProductSingleFigure>
        )}

          <StyledProductSingleHeader itemProp="name">
            {parse(title)}
          </StyledProductSingleHeader>
          
          {id == 'cG9zdDo3Mzcz' && 
            <ModalProvider backgroundComponent={FadingBackground}>
             <FancyModalButton id="cG9zdDo3Mzcz"/>
           </ModalProvider>
          }

        {fullPrice.edges[0].node.unit_amount !== 0 ? 
        <>
            <div className="product-price sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
              <meta itemProp="priceCurrency" content="GBP" />
              <Icon name="sale" /> <span itemProp="highPrice" className="strikethrough">{fullPriceFormatted}</span> <span itemProp="lowPrice">{salePriceFormatted}</span> 
            </div>
          
        <StyledProductExcerptSingle>{parse(excerpt)}</StyledProductExcerptSingle>
        
        <StyledProductBookingButtons>
        {props.sale ?
              props.tag.nodes[0].slug.includes('permission') ? 
              <span>
                <StyledRegisterNowButton 
                onClick={() => {
                  
                  checkoutSingleItem({sku: salePrice.edges[0].node.id})
                }
              }>Buy now ${salePriceWithDecimal}</StyledRegisterNowButton>
              <span className="or">or take advantage of this amazing offer</span>
              {/* {title.includes('Sleep') ? 
                <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Sleep AND Permission to Reset™ Anxiety<br /> (save $95.00) only $47.77</AnchorLink>
              :
              <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Anxiety AND Permission to Reset™ Sleep<br /> (save $95.00) only $47.77</AnchorLink>
              } */}
              
              </span>
              :
              <>
              <StyledRegisterNowButton 
              onClick={() => {
                  
                  checkoutSingleItem({sku: salePrice.edges[0].node.id})
                }
              }>Buy now</StyledRegisterNowButton>
              </>
        : props.tag.nodes[0].slug.includes('permission') ? 
        <span>
          <StyledRegisterNowButton 
          onClick={() => {
              checkoutSingleItem({sku: fullPrice.edges[0].node.id})
            }
          }>Get Access for Just ${fullPriceWithDecimal}</StyledRegisterNowButton>
          <StyledAnchorLink inverse="true" className="learn-more" to="#permissioning-offer">Special offer - save $32.00</StyledAnchorLink>
        </span>
        :
        <>
        <StyledRegisterNowButton 
          onClick={() => {
              checkoutSingleItem({sku: fullPrice.edges[0].node.id})
            }
          }>Buy now</StyledRegisterNowButton>
        </>
        }

        {/* <StyledNotice>All audios, videos and content found on Karina Grant's website, is exclusively copyrighted by KG and/or its affiliates. No content may be copied, reproduced, republished, downloaded, posted or transmitted in any way. You may not “mirror” any content contained on this Site without express written consent from Karina Grant.</StyledNotice> */}
      </StyledProductBookingButtons>
      
      </>
      :
        null
      }

      <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
        <meta itemProp="name" content="Karina Grant" />
      </div>
      </span>
      <div className="product-description" itemProp='description'>
      {props.tag.nodes[0].slug.includes('permission') ?
        <>
            {props.id === "cG9zdDo3MjM0" ? 
              <ResetAnxiety title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id}/>
            : props.id === "cG9zdDo3ODE2" ?
              <ResetCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id}/>
            :
              <SleepCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id}/>
            }
        </>
      : 
        parse(content)
      }
      </div>
    </StyledProductSingleCard>
  )
}

export default SingleProductLanderOfffer1
